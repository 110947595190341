<template>
    <!--识图-->
    <video ref="videoRef" class="video_div" :style="style()" @touchstart="handleTouchStart" @touchend="handleTouchEnd"
        autoplay playsinline :poster="`/app_h5/image/Camera.png`"></video>
</template>

<script>
export default {
    props: ['module'],
    data() {
        return {
            translateX: 0,
            translateY: 0,
            dragging: false,
            lastX: 0,
            lastY: 0,
            windowWidth: 0,// 视口尺寸  
            windowHeight: 0,
            imgSocket: null,
            img_video: null,
            photoGraphId: null,//传图计时器
            videoPoster: '',
            mediaRecorder: null,
            slSocket: null,//信令webSocket
            peerConnection: null
        };
    },

    computed: {
        style() {
            return function () {
                return {
                    'transform': `translate3d(${this.translateX}px, ${this.translateY}px, 0)`,
                    'top': this.module.top == 0 ? '44px' : (44 + this.module.top) + 'px'
                }
            }
        }
    },

    mounted() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    },

    methods: {

        //识图功能(通过webRTC端对端视频流)
        createSocket() {
            this.img_video = this.$refs.videoRef;
            this.img_video.srcObject = this.module.videoStream;

            this.slSocket = new WebSocket(this.$base.slServer);
            this.slSocket.onopen = () => {
                this.slSocket.send(JSON.stringify({ 'uuid': "ceshi" }));// 发送初始消息给信令服务器
            };

            // 等待视频元数据加载完成
            this.img_video.onloadedmetadata = () => {
                const config = {
                    iceServers: [
                        { urls: 'turn:47.116.196.10:3479', username: 'kamikasi', credential: 'kmks' }
                    ],
                };
                this.peerConnection = new RTCPeerConnection(config);

                //推流
                this.module.videoStream.getTracks().forEach(track => {
                    this.peerConnection.addTrack(track, this.module.videoStream);
                });

                setTimeout(() => {
                    this.createOffer();
                    this.createPeerConnection();
                }, 1000);
                
                this.slSocket.onmessage = async (event) => {
                    //解码处理
                    if (event.data instanceof Blob) {
                        const reader = new FileReader();
                        reader.onload = async (e) => {
                            try {
                                //此处blob后端用的是UTF-8编码的二进制JSON
                                const jsonString = new TextDecoder('utf-8').decode(e.target.result);
                                const message = JSON.parse(jsonString);
                                if (message.sdp) {
                                    await this.peerConnection.setRemoteDescription(new RTCSessionDescription({
                                        sdp: message.sdp,
                                        type: message.type
                                    }));
                                    if (message.type === 'offer') {
                                        const answer = await this.peerConnection.createAnswer();
                                        await this.peerConnection.setLocalDescription(answer);
                                        let jsonString = JSON.stringify({ type: 'answer', sdp: this.peerConnection.localDescription.sdp, target_uuid: 'rtc' });
                                        let blob = new Blob([jsonString], { type: 'application/json' });
                                        this.slSocket.send(blob);
                                    }
                                } else if (message.candidate) {
                                    await this.peerConnection.addIceCandidate(new RTCIceCandidate(message.candidate));
                                }
                            } catch (error) {
                                // 处理解析错误
                            }
                        };
                        // 读取blob数据
                        reader.readAsArrayBuffer(event.data);
                    } else {
                        //流文件格式不属于Blob
                    }

                }
            }

        },

        createPeerConnection() {
            var that = this;
            // 监听ICE连接状态变化
            that.peerConnection.oniceconnectionstatechange = () => {
                if (that.peerConnection.iceConnectionState === 'disconnected' || that.peerConnection.iceConnectionState === 'failed') {
                    //监听ICE主动断开
                }
            };

            that.peerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                    if (that.slSocket.readyState === WebSocket.OPEN) {
                        let jsonString = JSON.stringify({ type: 'candidate', candidate: event.candidate, target_uuid: 'rtc' });
                        let blob = new Blob([jsonString], { type: 'application/json' });
                        that.slSocket.send(blob);
                    }
                }
            };

            return that.peerConnection;
        },

        //发送offer
        async createOffer() {
            const offerOptions = {
                offerToReceiveAudio: true,
                offerToReceiveVideo: true
            };
            const offer = await this.peerConnection.createOffer(offerOptions);
            await this.peerConnection.setLocalDescription(offer);
            if (this.slSocket && this.slSocket.readyState === WebSocket.OPEN) {
                let jsonString = JSON.stringify({
                    type: 'offer',
                    sdp: this.peerConnection.localDescription.sdp,
                    target_uuid: 'rtc'
                });
                let blob = new Blob([jsonString], { type: 'application/json' });
                this.slSocket.send(blob);
            }
        },

        //识图(通过视频帧转图片传给服务器)
        sendImg() {
            this.imgSocket = new WebSocket(this.$base.threeJs_ws + '8999/');
            this.imgSocket.onopen = () => {
                this.imgSocket.send("uuid:" + "web" + this.module.uuid + "|" + this.module.modelName)
            }

            this.imgSocket.onclose = (event) => {
                if (event.wasClean) {
                    console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
                } else {
                    // 例如：连接异常中断
                    console.error('[close] Connection died');
                }
            }

            this.img_video = this.$refs.videoRef;
            this.img_video.srcObject = this.module.videoStream;

            // 等待视频元数据加载完成
            this.img_video.onloadedmetadata = () => {
                //每隔1s执行拍照
                this.photoGraphTimer()
            }
        },

        //拍照计时器
        photoGraphTimer() {
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');

            // 设置canvas大小与视频一致
            canvas.width = this.img_video.videoWidth;
            canvas.height = this.img_video.videoHeight;

            // 绘制视频帧到canvas
            context.drawImage(this.img_video, 0, 0, canvas.width, canvas.height);
            // 将canvas转换为Blob
            canvas.toBlob((blob) => {
                //发送Blob到WebSocket服务器
                this.imgSocket.send(blob);
            }, 'image/jpeg', 0.5);
            this.photoGraphId = setTimeout(this.photoGraphTimer.bind(this), 100); // 500ms更新一次
        },

        updateWindowDimensions() {
            this.windowWidth = window.innerWidth - 20;
            this.windowHeight = window.innerHeight - (this.module.top == 0 ? '44' : 44 + this.module.top) * 3;
        },

        handleTouchStart(e) {
            // 立即开始拖动  
            this.dragging = true;
            this.lastX = e.touches[0].clientX;
            this.lastY = e.touches[0].clientY;
            // 添加touchmove事件监听器 
            document.addEventListener('touchmove', this.handleTouchMove, { passive: false });
            e.preventDefault();
        },

        handleTouchMove(e) {

            if (this.dragging) {
                let deltaX = e.touches[0].clientX - this.lastX;
                let deltaY = e.touches[0].clientY - this.lastY;

                // 计算新的位置  
                let newX = this.translateX + deltaX;
                let newY = this.translateY + deltaY;

                // 边界检查  
                newX = Math.min(Math.max(newX, 0), this.windowWidth - this.$refs.videoRef.offsetWidth);
                newY = Math.min(Math.max(newY, 0), this.windowHeight - this.$refs.videoRef.offsetHeight);

                // 更新位置  
                this.translateX = newX;
                this.translateY = newY;

                // 更新上一次触摸点  
                this.lastX = e.touches[0].clientX;
                this.lastY = e.touches[0].clientY;
            }
        },

        //拖动结束
        handleTouchEnd() {
            document.removeEventListener('touchmove', this.handleTouchMove);
            this.dragging = false;
        },

        //清空
        clearUpdata() {
            //清除识图计时器
            clearTimeout(this.photoGraphId);
            this.photoGraphId = null;

            //清除视口监听
            window.removeEventListener('resize', this.updateWindowDimensions);

            //关闭rtc
            this.peerConnection.close();
        },
    }
};  
</script>

<style>
.video_div {
    position: absolute;
    z-index: 105;
    width: 100px;
    max-width: 100px;
    cursor: move;
    user-select: none;
    text-align: center;
    border-radius: 5px;
    left: 10px;
}
</style>