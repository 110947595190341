<template>
  <div class="page">
    <!--可拖动的视频窗口-->
    <videoCom v-if="isShow" ref="videoRef" @postMsg="postMsg" :module="jsonInfo" />
    <!--顶部导航-->
    <div class="header" :style="style(0)">
      <div :style="style(1)" class="back_view">
        <el-select v-if="isShow && !appFromBoolean" class="select" v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label">
          </el-option>
        </el-select>
        <img v-if="isShow && appFromBoolean" class="icon" src="/app_h5/image/arrow-left.png"
          @click="postMsg({ msg: '', type: 'back' })" />
        <div v-if="isShow" class="title">{{ modelName }}</div>
        <img v-if="isShow" src="/app_h5/image/video_icon.png" class="icon_video" @click="setVideoMedia" />
      </div>
    </div>
    <!--three.js 3D模块-->
    <threeCom v-show="appFromType == '3d'" ref="threeScene" @show="show()" />
    <!--rtc 模块-->
    <rtcCom v-show="appFromType == 'rtc'" ref="rtcScene" @setRtcUpdata="setRtcUpdata()" @show="show()" />
    <!--visme 模块-->
    <vismeCom v-show="appFromType == 'visme'" ref="vismeScene" @show="show()" />
    <!--底部视频、语音、文字输入组件-->
    <!-- <inputCom v-if="isShow"/> -->
    <!--语音输入按钮-->
    <div v-if="isShow" class="buttom_div">
      <div v-if="actionType" class="audio_div" @mousedown="handleMouseDown" @mouseup="handleMouseUp"
        @mouseleave="handleMouseLeave" @touchstart="handleMouseDown" @touchend="handleMouseUp"
        @touchcancel="handleMouseLeave">
        <img src="/app_h5/image/audio_icon.png" @click.stop>
      </div>
      <div v-if="this.appFromType == '3d'" class="audio_div" @click="actionClick(actionType)">
        <div class="">{{ actionType ? '开始' : '结束' }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import threeCom from "../components/threeCom";
import rtcCom from "../components/rtcCom.vue"
import vismeCom from "../components/vismeCom"
import videoCom from "../components/videoCom"
// import inputCom from "../components/inputCom"
import { webviewGetMessage } from '../utils/appToWebview';
export default {
  components: {
    threeCom,
    rtcCom,
    vismeCom,
    videoCom,
    // inputCom
  },
  data() {
    return {
      options: [{
        value: '选项1',
        label: '3d'
      }, {
        value: '选项2',
        label: 'rtc'
      }, {
        value: '选项3',
        label: 'visme'
      }],
      value: localStorage.getItem("value") || '3d',
      audioUrl: '',
      appFromType: '',//3d&rtc&visme
      appFromBoolean: false,//是否是从app进入
      isShow: false,
      modelName: '',
      top: 0,
      timer: null, // 长按计时器
      uuid: null,
      audioStream: null,
      videoStream: null,
      audioSocket: null,
      audioContext: null,
      source: null,
      processor: null,
      facingMode: 'environment',
      jsonInfo: null,
      actionType: true
    }
  },
  watch: {
    value(newVal) {
      localStorage.setItem("value", newVal);
      this.value = newVal
      window.location.reload();
    }
  },
  computed: {
    style() {
      return function (index) {
        return {
          'height': index == 0 ? (44 + this.top) + 'px' : '44px',
          'align-items': 'center',
          'padding-top': index == 1 ? this.top + 'px' : ''
        }
      }
    }
  },

  created() {
    //接收app传参
    webviewGetMessage("msgFromApp", (params) => {
      this.appFromBoolean = true
      this.setParameter(JSON.parse(JSON.stringify(params)));
    });
  },

  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    //获取音视频权限
    this.gainPower()
  },

  beforeDestroy() {
    // 清理资源，移除事件监听器
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },

  methods: {

    handleBeforeUnload() {
      this.postMsg({ msg: '', type: 'back' })
    },

    //设置样式,接收参数(app端)
    setParameter(json) {
      this.modelName = json.modelName;
      this.top = json.top;
      this.uuid = json.uuid;
      this.appFromType = json.modelType;

      setTimeout(() => {
        switch (this.appFromType) {
          case '3d':
            //3d模块
            this.audioUrl = this.$base.threeJs_ws + '9950/';
            this.$refs.threeScene.Update(json);
            break;
          case 'rtc':
            //rtc模块
            this.audioUrl = this.$base.rct_ws + '9951/';
            this.$refs.rtcScene.rtcStop(json);
            break;
          case 'visme':
            //visme模块
            this.audioUrl = this.$base.rct_ws + '9951/';
            this.$refs.vismeScene.Update(json);
            break;
        }
        //创建语音webSocket连接
        this.createSocket();
      }, 1000);

    },

    //连接rtc
    setRtcUpdata() {
      this.$refs.rtcScene.Update();
    },

    createSocket() {
      this.audioSocket = new WebSocket(this.audioUrl);
      this.audioSocket.onopen = () => {
        this.audioSocket.send("ernerf:web" + this.uuid + "|" + this.modelName + ":asr_start")
      }

      if (this.appFromType == 'visme') {
        this.audioSocket.onmessage = (event) => {
          this.$refs.vismeScene.startDisplayingText(event.data);
          //请求visme接口
          setTimeout(() => {
            this.$refs.vismeScene.getVismeInfo();
          }, 1000);
        }
      }

    },

    async gainPower() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        this.postMsg({ msg: '当前设备不支持麦克风', type: 'NotSupportedMedia' });
        return;
      }

      try {
        //获取音频流
        this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        //获取视频流
        this.videoStream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: this.facingMode } });
        this.opneWindow();
      } catch (error) {
        this.postMsg({ msg: '获取音频流失败', type: 'error' });
      }
    },

    //打开网页
    opneWindow() {
      //从网页打开
      setTimeout(() => {
        if (!this.appFromBoolean) {
          //测试数据
          var json = {
            modelName: 'girl04',
            top: 0,
            uuid: 'ceshi123'
          }
          this.appFromType = this.value
          this.modelName = 'girl04';
          this.top = 0;
          this.uuid = 'ceshi123';
          switch (this.appFromType) {
            case '3d':
              //3d模块
              this.audioUrl = this.$base.threeJs_ws + '9950/';
              this.$refs.threeScene.Update(json);
              break;
            case 'rtc':
              //rtc模块
              this.audioUrl = this.$base.rct_ws + '9951/';
              this.$refs.rtcScene.rtcStop(json);
              break;
            case 'visme':
              //visme模块
              this.audioUrl = this.$base.rct_ws + '9951/';
              this.$refs.vismeScene.Update(json);
              break;
          }
          //创建语音webSocket连接
          this.createSocket();
        }
      }, 1000);
    },

    //前后摄像头切换user前置、environment后置
    async setVideoMedia() {
      this.facingMode = this.facingMode == 'environment' ? 'user' : 'environment';
      this.$refs.videoRef.clearUpdata();//清除视频流模块中的耗时操作
      //清空视频流
      if (this.videoStream) {
        this.videoStream.getTracks().forEach(track => track.stop());
        this.videoStream = null;
      }
      this.videoStream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: this.facingMode } });
      this.setSendImg();
    },

    //监听用户长按开始
    handleMouseDown(event) {
      // 清除之前的计时器，以防多次触发
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      // 设置计时器
      this.timer = setTimeout(() => {
        //发送语音
        this.startRecording();
      }, 10);
      // 阻止默认行为
      event.preventDefault();
    },

    //监听用户长按结束
    handleMouseUp(event) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
        //结束语音
        this.stopRecording();
      }
      event.preventDefault();
    },

    //监听用户长按结束
    handleMouseLeave(event) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
        //结束语音
        this.stopRecording();
      }
      event.preventDefault();
    },

    //开始录音
    startRecording() {
      if (!this.audioStream) {
        //授权麦克风
        this.postMsg({ msg: '请允许访问您的麦克风', type: 'permission' });
        return;
      }

      var that = this;
      //获取话筒权限
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.source = this.audioContext.createMediaStreamSource(this.audioStream);
      this.processor = this.audioContext.createScriptProcessor(16384, 1, 1);

      this.processor.onaudioprocess = (e) => {
        const input = e.inputBuffer.getChannelData(0);
        const buffer = new Int16Array(input.length);
        for (let i = 0; i < input.length; i++) {
          buffer[i] = Math.min(input[i] * 32767, 32767); // 转换为16位整数并防止溢出
          buffer[i] = Math.max(buffer[i], -32768); // 防止下溢
        }
        if (that.audioSocket && that.audioSocket.readyState === WebSocket.OPEN) {
          that.audioSocket.send(new Int8Array(buffer.buffer)); // 发送 ArrayBuffer
        }
      };

      this.source.connect(this.processor);
      // 如果不需要监听输出，则不连接到 audioContext.destination
      this.processor.connect(this.audioContext.destination);
    },

    //结束录音
    stopRecording() {
      var that = this;

      // 停止音频处理器
      if (that.processor) {
        that.processor.disconnect(); // 断开音频处理器的连接
        that.processor.onaudioprocess = null; // 清除事件处理器
        that.processor = null; // 清除处理器引用
      }

      // 停止音频源
      if (that.source) {
        that.source.disconnect(); // 断开音频源的连接
        that.source = null; // 清除源引用
      }

      // 发送一个结束录音的信号给语音转文字webSocket服务器
      if (that.audioSocket && that.audioSocket.readyState === WebSocket.OPEN) {
        that.audioSocket.send("end");
      }
    },

    //3d动作模仿
    actionClick(type) {
      this.actionType = type ? false : true
      this.$refs.threeScene.actionClick(type);
    },

    //模型加载完成
    show() {
      console.log('A=A=====')
      this.isShow = true
      this.setSendImg();
    },

    setSendImg() {

      this.jsonInfo = {
        modelName: this.modelName,
        top: this.top,
        uuid: this.uuid,
        videoStream: this.videoStream
      }
      setTimeout(() => {
        this.$refs.videoRef.createSocket();//识图功能(通过webRTC端对端视频流)
        //this.$refs.videoRef.sendImg();//识图功能(通过视频帧转图片传给服务器)
      }, 10);
    },

    //给app传递消息
    postMsg(info) {

      //返回键逻辑
      if (info.type == 'back') {
        //清除音频流并关闭麦克风
        if (this.audioStream) {
          this.audioStream.getTracks().forEach(track => track.stop());
          this.audioStream = null;
        }
        if (this.videoStream) {
          this.videoStream.getTracks().forEach(track => track.stop());
          this.videoStream = null;
        }

        switch (this.appFromType) {
          case '3d':
            //3d模块
            this.$refs.threeScene.clearUpdata();//清除three.js中的模型
            break;
          case 'rtc':
            //rtc模块
            this.$refs.rtcScene.clearUpdata();
            break;
        }
        this.$refs.videoRef.clearUpdata();//清除视频流模块中的耗时操作
      }

      this.$webUni.postMessage({
        data: {
          action: "appSaveMsgInfo",
          params: info,
        }
      })
    }

  }
}
</script>
<style scoped>
.page {
  background: rebeccapurple;
}

.header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 110;
}

.back_view {
  display: flex;
  align-items: center;
  z-index: 2;
}

.select {
  position: absolute;
  left: 10px;
  width: 90px;
}

.icon {
  position: absolute;
  left: 10px;
  width: 30px;
  height: 25px;
}

.icon_video {
  position: absolute;
  right: 10px;
  width: 30px;
  height: 25px;
}

.title {
  font-size: 18px;
  font-weight: 800;
  z-index: 2;
}

.buttom_div {
  position: absolute;
  max-width: 100%;
  display: flex;
  justify-content: center;
  bottom: 10px;
  width: 100vw;
}

.audio_div {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  margin: 0 10px;
  align-items: center;
  z-index: 102;
  bottom: 10px;
  background: #fff;
  border-radius: 100%;
  left: calc(50% - 30px);
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    /* 外部阴影 */
    0 1px 3px rgba(0, 0, 0, 0.3);
}

.audio_div img {
  width: 40px;
  height: 40px;
  padding: 10px 10px;
}
</style>