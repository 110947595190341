<template>
    <div class="page">
        <div class="text_div">
            <div>{{ displayResult }}</div>
        </div>
        <canvas class="canvas" ref="canvas"></canvas>
        <div v-if="canvasAnalyser" class="canvas_div">
            <canvas class="drawCanvas" ref="drawCanvas"></canvas>
        </div>
    </div>
</template>

<script>
import updated_output from "../assets/viseme911_out_muse1/coord.json"
export default {
    data() {
        return {
            module: null,
            canvas: null,
            ctx: null,
            canvas_face: null,
            ctx_face: null,
            currentFrame: 0, // 当前帧
            frameCount: 100, // 总帧数
            direction: 1, // 1表示正向，-1表示反向
            intervalId: null, // 定时器ID
            typeIndex: 0,//嘴型数组下标
            typeArr: [],//嘴型数组
            timer: null, // 用于计时器
            uuid: localStorage.getItem("uuid"),
            audioscoket: null,//语音webSocket
            vsimeScoket: null,//数据
            canvasAnalyser: false,
            result: '',
            displayResult: '',
            audioUrl: '',
            vsimeBoolean: true,
            audioArr: [],//音频数组
            chatArr: [],//回答内容数组
            audioIndex: 0,//音频数组下标
            startTime: 0,
            endTime: 0,
            time: 0,
            video: null,
            img: null,
            images: [], // 存储加载的大图
            imagesMix: [],//存储加载的小图
            audioContext: null,
            playBoolean: false,
            updateTimer: null
        };
    },

    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
        if (this.vsimeScoket) {
            this.vsimeScoket.close(); // 关闭WebSocket连接  
            this.vsimeScoket = null;
        }
        this.images = [];
        this.imagesMix = []; // 如果不再需要，也清空这个数组  

        // 清理Canvas相关资源  
        this.canvas = null;
        this.ctx = null;
        this.img = null;

        // 停止动画  
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }

        // 停止任何正在进行的音频操作
        if (this.audioContext) {
            this.audioContext.close(); // 关闭AudioContext  
            this.audioContext = null;
        }
    },

    methods: {

        //补帧的方法
        interpolateImages() {

            const img1 = this.$cv.imread(this.imagesMix[0][4]);
            const img2 = this.$cv.imread(this.imagesMix[0][12]);

            // 简单的图像融合作为补帧的模拟  
            const alpha = 0.5; // 融合系数  
            const interpolated = new this.$cv.Mat(this.imagesMix[0][11].width,this.imagesMix[0][11].height,this.$cv.CV_32F);

            this.$cv.addWeighted(img1, alpha, img2, 1 - alpha, 0, interpolated);
            this.$cv.imshow(this.canvas, interpolated)
            console.log('A==A=====', interpolated)

            // 释放图像资源  
            img1.delete();
            img2.delete();
            interpolated.delete();
        },

        //更新数据
        Update(data) {
            this.module = data;
            this.vsimeScoket = new WebSocket(this.$base.vsime_ws);
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
            this.initCanvas();
        },

        //实例化canvas
        async initCanvas() {
            this.canvas = this.$refs.canvas;
            this.ctx = this.canvas.getContext('2d');
            try {
                await Promise.all([
                    this.preloadImages(100),//缓存大图总帧数
                    this.preloadImagesMix(100, 16)//缓存小图总帧数
                ]);

                this.startAnimation();
                this.$emit('show');
                //this.interpolateImages();
            } catch (err) {
                console.error('Error loading images:', err);
            }
        },

        //预加载大图
        preloadImages(frameCount) {
            return new Promise((resolve, reject) => {
                const urls = this.getFrameURLs(frameCount);
                const images = urls.map(src => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.onload = () => resolve(img);
                        img.onerror = reject;
                        img.src = src;
                    });
                });

                Promise.all(images).then((loadedImages) => {
                    this.images = loadedImages;
                    resolve(); // 加载完成后解决 Promise  
                }).catch(err => {
                    reject(err); // 加载失败时拒绝 Promise  
                });
            });
        },

        //获取大图所有帧的URL  
        getFrameURLs(frameCount) {
            let urls = [];
            for (let i = 0; i < frameCount; i++) {
                urls.push(require(`@/assets/viseme911_out_muse1/src/${i}.jpg`));
            }
            return urls;
        },

        //预加载小图
        preloadImagesMix(frameCount, count) {
            return new Promise((resolve, reject) => {
                const promises = [];
                for (let i = 0; i < frameCount; i++) {
                    const framePromises = [];
                    for (let j = 0; j < count; j++) {
                        framePromises.push(new Promise((resolve, reject) => {
                            const img = new Image();
                            img.onload = () => resolve(img);
                            img.onerror = reject;
                            img.src = require(`@/assets/viseme911_out_muse1/viseme/${i}/${j}.png`);
                        }));
                    }
                    promises.push(Promise.all(framePromises));
                }
                Promise.all(promises).then(frameImagesArrays => {
                    this.imagesMix = frameImagesArrays; // 使用 flat() 来扁平化数组
                    resolve(); // 加载完成后解决 Promise  
                }).catch(err => {
                    reject(err); // 加载失败时拒绝 Promise  
                });
            });
        },

        //将大图和小图画上画布
        async drawFrameAsync(frameNum) {
            //绘制大图
            this.canvas.width = this.images[frameNum - 1].width;
            this.canvas.height = this.images[frameNum - 1].height;
            this.ctx.drawImage(this.images[frameNum - 1], 0, 0);
            if (this.playBoolean) {
                //绘制小图
                var frameImg = this.getMaps(frameNum - 1);
                // 绘制图像
                const x = updated_output[frameNum - 1][0];
                const y = updated_output[frameNum - 1][1];
                this.ctx.drawImage(frameImg, x, y, frameImg.width, frameImg.height);
            }
        },

        async updateFrameAsync() {
            // 更新当前帧
            this.currentFrame += this.direction;
            // 检查是否超出范围并调整
            if (this.currentFrame > this.frameCount) {
                this.currentFrame = this.frameCount - 1; // 保持在最后一帧，但下一帧会反向
                this.direction = -1;
            } else if (this.currentFrame < 1) {
                this.currentFrame = 2; // 保持在第一帧，但下一帧会正向
                this.direction = 1;
            }
            // 绘制当前帧  
            await this.drawFrameAsync(this.currentFrame);
            this.timeoutId = setTimeout(this.updateFrameAsync.bind(this), 40);
        },

        startAnimation() {
            // 清除之前可能存在的定时器  
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            // 开始动画  
            this.updateFrameAsync();
        },

        //获取嘴型贴图
        getMaps(index) {
            var imgUrl = ''
            if (this.typeIndex < this.typeArr[this.audioIndex].length && this.playBoolean) {
                imgUrl = this.imagesMix[index][this.typeArr[this.audioIndex][this.typeIndex]]

                this.typeIndex++
                return imgUrl
            }
        },

        //visme接口
        getVismeInfo() {

            var that = this;
            if (that.vsimeScoket && that.vsimeScoket.readyState === WebSocket.OPEN) {
                that.vsimeScoket.send(JSON.stringify({
                    'text': this.result
                }))
            }
            this.vsimeScoket.onmessage = (event) => {
                /**
                 * chat_res:回答的文字
                 * viseme_list:脸部表情数组
                 * audio_content:音频文件(base64格式)
                 */
                this.sendJson(JSON.parse(event.data));
            }
        },

        sendJson(data) {
            //文字处理
            if ('chat_res' in data) {
                this.chatArr.push(data.chat_res)
            }
            //脸部表情处理
            if ('viseme_list' in data) {
                this.typeArr.push(data.viseme_list)
            }
            //音频处理
            if ('audio_content' in data) {

                const binaryData = atob(data.audio_content).split('').map(char => char.charCodeAt(0));
                const arrayBuffer = new Uint8Array(binaryData).buffer;
                this.audioContext.decodeAudioData(arrayBuffer, (buffer) => {
                    this.audioArr.push(buffer);
                }, (error) => {
                    // 解码失败  
                    console.error("音频解码失败:", error);
                });
            }
            if (this.audioArr.length == 1 && this.vsimeBoolean) {
                //确保该方法只执行一次
                this.playNextAudio();
                this.vsimeBoolean = false
            }
        },

        //开始播放
        playNextAudio() {
            if (this.audioArr[this.audioIndex] != undefined) {
                //播放音频
                if (this.audioContext.destination.numberOfOutputChannels > 0) {
                    this.audioContext.destination.disconnect();
                }

                // 创建音频源节点并连接到输出
                const source = this.audioContext.createBufferSource();
                source.buffer = this.audioArr[this.audioIndex];
                source.connect(this.audioContext.destination);

                // 开始播放
                source.start(0);
                this.playBoolean = true;

                //显示文字
                this.result = this.chatArr[this.audioIndex]
                this.startDisplayingText(this.result);

                //监听播放结束
                source.onended = () => {
                    this.typeIndex = 0;
                    this.audioIndex++
                    this.playNextAudio();
                };

            } else {
                console.log("===回答完了==");
                this.audioIndex = 0;//重置音频下标
                this.audioArr = [];//清空音频数组
                this.chatArr = [];//清空回答内容数组
                this.vsimeBoolean = true;
                this.time = 0;
                this.typeArr = [];//重置嘴型数组
                this.typeIndex = 0;//重置嘴型下标
                this.playBoolean = false
            }
        },

        //文字逐个出现的动画
        startDisplayingText(data) {
            this.result = data;
            this.displayResult = ''
            this.intervalId = setInterval(() => {
                if (this.displayResult.length < this.result.length) {
                    this.displayResult += this.result.charAt(this.displayResult.length);
                } else {
                    // 文本已完全显示，清除定时器
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                    this.vsimeBoolean = true;
                }
            }, 160); // 160毫秒显示一个字符
        }

    }
};
</script>
<style scoped>
.page {
    max-width: 100%;
    max-height: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text_div {
    position: absolute;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #00c559;
    font-weight: bolder;
    bottom: 100px;
    z-index: 999;
    padding: 0 20px;
    max-height: 30%;
    /* 设置容器的最大高度 */
    overflow-y: auto;
    /* 当内容超出时显示垂直滚动条 */
    /* background: rgba(255, 255, 255, 0.1); */
}

.text_div::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera */
}

.canvas {
    width: 100%;
    max-height: 100vh;
}

.canvas_face {
    position: absolute;
    z-index: 111;
    width: 100%;
    max-height: 100vh;
}

.canvas_div {
    position: absolute;
    max-width: 100%;
    display: flex;
    justify-content: center;
    bottom: 22%;
}

.drawCanvas {
    position: absolute;
    z-index: 101;
}

.audio_div {
    width: 60px;
    height: 60px;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: 111;
    bottom: 10px;
    background: #eee;
    border-radius: 100%;
    left: calc(50% - 30px);
    box-shadow:
        0 4px 6px rgba(255, 255, 255, 0.5),
        /* 外部阴影 */
        0 1px 3px rgba(0, 0, 0, 0.5);
}

.audio_div img {
    width: 40px;
    height: 40px;
    padding: 10px 10px;
}
</style>