import Vue from 'vue'
import App from './App.vue'

import webUni from '@dcloudio/uni-webview-js'
Vue.prototype.$webUni = webUni

import cv from '@techstark/opencv-js'
Vue.prototype.$cv = cv

import base from './utils/base'
Vue.prototype.$base = base

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
