var threeJs_ws = 'wss://kamikasi.net/websocket/3090/'
var rct_ws = 'wss://kamikasi.net/websocket/rtc/'
var slServer = 'wss://kamikasi.net/websocket/3090/6667/'
var vsime_ws = 'wss://kamikasi.net/rtc/chat/'
const base = {
	threeJs_ws,
	rct_ws,
	vsime_ws,
	slServer
}
export default base