<template>
  <div id="app">
    <webViewCom />
  </div>
</template>

<script>
import webViewCom from './views/webViewCom.vue';
// import SherpaOnnxWasmMainVadAsr from './utils/sherpa-onnx-wasm-main-vad-asr.js'
export default {
  name: 'App',
  components: {
    webViewCom
  },
  // 组件选项  
  mounted() {  
    // 使用导入的SherpaOnnxWasmMainVadAsr  
    //SherpaOnnxWasmMainVadAsr();  
  }
}
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: rebeccapurple;
}

*::-webkit-scrollbar {
  display: none;
}

.your-scrollable-element::-webkit-scrollbar {
  display: none;
}

.firefox-hack {
  overflow: hidden;
}
</style>
