<template>
    <div class="threeScene" ref="threeScene"></div>
</template>
<script>
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
export default {
    data() {
        return {
            module: null,
            scene: null,
            camera: null,
            renderer: null,
            clock: null,
            currentModel: null,
            mixer: null,
            currentAnimation: null,
            animationId: null,
            blendShapeMesh: null,//脸部表情mesh
            eyeMesh: null,//眼睛mesh
            playNextBoolean: true,//流式传输时确保方法只执行一次
            actionArr: [],//动作数组
            audioBuffers: [],//音频数组
            blendshapeArr: [],//脸部表情数组
            currentBufferIndex: 0,//当前要播放的音频索引
            playNextAudioTimer: null,
            modelSocket: null,
            reconnectTime: null,
            actionType: true,
            audioContext: null,
            currentTime: null,
            currentFrame: null,
            framesPerSecond: null,
            updateInterval: null,
            updateTimer: null,
        }
    },

    mounted() {

    },

    methods: {

        //更新数据
        Update(data) {
            this.postMsg({ msg: '', type: 'loading' });
            this.module = data;
            //连接模型modelWebSocket
            this.modelWebSocket();
            this.init();
        },

        init() {

            // 场景，相机
            this.scene = new THREE.Scene();
            this.scene.background = new THREE.Color(0xffffff);
            this.camera = new THREE.PerspectiveCamera(7, window.innerWidth / window.innerHeight, 1, 1000);
            this.camera.position.set(0, 0, 32);//相机位置

            // 创建一个环境光源，它会向所有方向均匀发射光线
            const ambientLight = new THREE.AmbientLight(0xffffff, 2); //光源，强度为2
            this.scene.add(ambientLight);

            // 设置点光源的位置
            const pointLight = new THREE.PointLight(0xffffff, 500, 1000); //光源，衰减距离为1000
            pointLight.castShadow = true; // 启用阴影投射
            pointLight.position.set(3, 7, 15); // 光源位置
            // 将点光源添加到场景中
            this.scene.add(pointLight);

            //模型影子接收
            const planeMat = new THREE.MeshLambertMaterial();
            const plane = new THREE.Mesh(new THREE.PlaneGeometry(50, 50), planeMat);
            plane.position.set(0, -0.9, 0);
            plane.receiveShadow = true;
            plane.rotation.x = -Math.PI * 0.5;
            this.scene.add(plane);

            // 渲染器
            this.renderer = new THREE.WebGLRenderer({ antialias: true });//去掉锯齿
            this.renderer.shadowMap.enabled = true; //启用阴影投射
            this.renderer.antialias = true;
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            this.$refs.threeScene.appendChild(this.renderer.domElement);

            // 控制器
            let control = new OrbitControls(this.camera, this.renderer.domElement);
            control.enableZoom = false; // 缩放
            control.enablePan = false; // 平移
            control.maxPolarAngle = Math.PI / 2; //2.8 1.8 Math.PI / 1

            //创建一个时钟对象Clock
            this.clock = new THREE.Clock();

            //加载模型
            this.loaderModel();

            //窗口监视
            window.addEventListener('resize', this.onWindowResize, false);

        },


        loaderModel() {

            //加载没有动画的glb模型文件
            const loader = new GLTFLoader()
            loader.load(`/app_h5/model/${this.module.modelName}.glb`, (gltf) => {

                if (this.currentModel) {
                    this.scene.remove(this.currentModel)
                }
                //保存当前模型实例，方便后续操作
                this.currentModel = gltf.scene;
                this.currentModel.position.set(0, this.modelAdatation(this.module.modelName), 0)
                //将模型添加到场景中
                this.scene.add(this.currentModel);

                // 停止前一个动画（如果存在）
                if (this.mixer) {
                    this.mixer.stopAllAction();
                    this.mixer.uncacheRoot(gltf);//释放内存
                }

                //创建动画混合器
                this.mixer = new THREE.AnimationMixer(this.currentModel);
                // 初始化动画状态
                this.currentAnimation = this.mixer.clipAction(gltf.animations[0]);
                // 设置动画的结束回调
                this.currentAnimation.setLoop(THREE.LoopRepeat);//循环播放动作
                this.currentAnimation.play();//播放动画

                //递归遍历currentModel包含所有的模型节点
                this.currentModel.traverse((child) => {
                    //防止模型穿模
                    child.frustumCulled = false;
                    //设置模型投影
                    child.castShadow = true;
                    /**
                     * child.isMesh:判断模型对象child是不是网格模型'Mesh'
                     * child.name:判断约定使用的模型节点名称为Head
                    */
                    if (child.isMesh) {
                        //脸部表情
                        if (child.name == 'Head' || child.name == 'Head_1') {
                            this.blendShapeMesh = child;
                            return false; // 找到后中断遍历
                        }
                        //调整眼球位置
                        if (child.name == 'Eye' || child.name == 'EyeOcclusion' || child.name == 'TearLine' || child.name == 'Lashes' || child.name == 'Brow') {
                            this.eyeMesh = child;
                            return false; // 找到后中断遍历
                        }
                    }
                    //修改模型材质
                    if (child.material) {
                        child.material.metalness = this.modelName == 'mouse' ? 0.5 : 0;//修改金属感
                        //child.material.roughness = 1; //修改粗糙度
                    }
                });

                this.animate();

                this.$emit('show')
                this.postMsg({ msg: '', type: 'ending' });
            }, undefined, () => {
                // 加载失败时的回调函数
                this.postMsg({ msg: '模型加载失败', type: 'back' });
            });
        },

        //循环动画
        animate() {
            this.animationId = requestAnimationFrame(this.animate);
            // 更新动画混合器
            if (this.mixer) {
                const delta = this.clock.getDelta(); // 确保clock已经正确初始化并更新
                this.mixer.update(delta);
            }
            this.renderer.render(this.scene, this.camera);
        },

        //3d动作模仿
        actionClick(type) {
            this.actionType = type ? false : true
            let jsonString = JSON.stringify({ 'signal': type ? 'motion_start' : 'motion_stop', 'target_uuid': 'romp_client', 'uuid': 'web' + this.module.uuid + "|" + this.module.modelName });
            let blob = new Blob([jsonString], { type: 'application/json' });
            this.modelSocket.send(blob);

            if (!type) {
                this.publicAction(`/app_h5/model/girl03_dongzuo.glb`, 0);
            }
        },

        //模型webSocket
        modelWebSocket() {

            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
            this.modelSocket = new WebSocket(this.$base.threeJs_ws + '9999/');

            // 连接打开时触发
            this.modelSocket.onopen = () => {
                // 发送消息到服务器
                this.modelSocket.send(JSON.stringify({ 'uuid': "web" + this.module.uuid + "|" + this.module.modelName }));
                let jsonString = JSON.stringify({ 'uuid': "web" + this.module.uuid + "|" + this.module.modelName, 'target_uuid': 'shitu' });
                let blob = new Blob([jsonString], { type: 'application/json' });
                this.modelSocket.send(blob);
            };

            var that = this;
            // 收到服务器消息时触发
            this.modelSocket.onmessage = (event) => {

                clearTimeout(this.reconnectTime);//清除定时器

                //解码处理
                if (event.data instanceof Blob) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        try {
                            //此处blob后端用的是UTF-8编码的二进制JSON
                            const jsonString = new TextDecoder('utf-8').decode(e.target.result);
                            const jsonData = JSON.parse(jsonString);
                            //转动眼球的函数
                            if ('position' in jsonData) {
                                // 获取BlendShape权重数组
                                const morphTargetInfluences = this.blendShapeMesh.morphTargetInfluences;
                                // 获取eye权重数组
                                const eyeMeshInfluences = this.eyeMesh.morphTargetInfluences;
                                // 获取当前帧的权重数据
                                const currentWeights = jsonData.position;
                                // 将权重数据设置到模型上
                                if (currentWeights && morphTargetInfluences) {
                                    for (let i = 0; i < Math.min(morphTargetInfluences.length, currentWeights.length); i++) {
                                        morphTargetInfluences[i] = currentWeights[i];
                                        eyeMeshInfluences[i] = currentWeights[i];
                                    }
                                }
                            } else {
                                /**
                                 * 处理解析后的JSON数据
                                 * 动作:{animation:.glb二进制文件,target_uuid:uuid} 
                                 * 语音:{audio:.wav二进制文件,target_uuid:uuid}
                                 * 脸部表情:{blendshape:.json文件,target_uuid:uuid}
                                */
                                that.handleJsonData(jsonData);
                            }
                        } catch (error) {
                            // 处理解析错误
                        }
                    };
                    // 读取blob数据
                    reader.readAsArrayBuffer(event.data);
                } else {
                    //流文件格式不属于Blob
                }
            }

            // 连接关闭时触发
            this.modelSocket.onclose = (event) => {
                console.log('event: ', event);
                if (event.wasClean) {
                    console.log('Connection closed cleanly, code=' + event.code + ' reason=' + event.reason);
                } else {
                    this.reconnectTime = setTimeout(() => {
                        this.modelWebSocket()
                    }, 100);
                }
            }

            //模型webSocket报错
            this.modelSocket.onerror = () => { }
        },

        //模型动作、嘴型、语音处理
        handleJsonData(data) {
            //处理.glb文件
            if ('animation' in data) {
                // 创建一个Blob对象
                const binaryData = Uint8Array.from(data.animation, c => c.charCodeAt(0));
                const blob = new Blob([binaryData.buffer], { type: 'application/octet-stream' });
                if (!this.actionType) {
                    this.publicAction(URL.createObjectURL(blob), 1)
                } else {
                    this.actionArr.push(URL.createObjectURL(blob))
                }
            }

            //处理.wav文件
            if ('audio' in data) {
                // 处理.wav文件,使用AudioContext解码音频数据
                const binaryData = Uint8Array.from(data.audio, c => c.charCodeAt(0));
                // 解码音频数据为binaryData.buffer
                this.audioContext.decodeAudioData(binaryData.buffer, (buffer) => {
                    this.audioBuffers.push(buffer); // 将解码后的音频缓冲区添加到数组中
                }, (error) => {
                    //解码音频数据失败
                    console.log("==音频解码error====", error)
                });
            }

            //处理blendshape文件(json文件)
            if ('blendshape' in data) {
                this.blendshapeArr.push(data.blendshape)
            }

            //确保该方法只执行一次
            if (this.audioBuffers.length == 1 && this.playNextBoolean) {
                this.playNextAudio();
                this.playNextBoolean = false
            }
        },

        //执行语音、嘴型、动作对齐逻辑
        playNextAudio() {
            if (this.audioBuffers[this.currentBufferIndex] !== undefined) {
                if (this.actionArr[this.currentBufferIndex] !== undefined && this.blendshapeArr[this.currentBufferIndex] !== undefined) {
                    //清除计时器
                    clearTimeout(this.playNextAudioTimer);
                    this.playNextAudioTimer = null;

                    // 停止之前的音频源
                    if (this.audioContext.destination.numberOfOutputChannels > 0) {
                        this.audioContext.destination.disconnect();
                    }

                    // 创建音频源节点并连接到输出
                    const source = this.audioContext.createBufferSource();
                    source.buffer = this.audioBuffers[this.currentBufferIndex];
                    source.connect(this.audioContext.destination);

                    // 开始播放
                    source.start(0);

                    // 脸部表情更新函数
                    this.currentTime = 0; // 计时器
                    this.currentFrame = 0; // 当前帧
                    this.framesPerSecond = 60; // 每秒帧数
                    this.updateInterval = 1000 / this.framesPerSecond; // 更新间隔
                    this.updateFaceExpressions()

                    //执行动作
                    this.publicAction(this.actionArr[this.currentBufferIndex], 1);

                    // 监听播放结束事件
                    var that = this;
                    source.onended = () => {
                        console.log("=as=as=音频播完了====")
                        that.currentBufferIndex++; //增加播放索引
                        clearTimeout(that.updateTimer);//清除定时器
                        that.playNextAudio(); //播放下一个音频
                    };
                } else {
                    this.playNextAudioTimer = setTimeout(() => {
                        this.playNextAudio()
                    }, 100);
                }
            } else {
                //重置模型初始动作
                this.publicAction(`/app_h5/model/girl03_dongzuo.glb`, 0);
                this.audioBuffers = []; //初始化音频数组
                this.actionArr = [];//初始化动作数组
                this.blendshapeArr = [];//初始化脸部表情数组
                this.currentBufferIndex = 0;//初始化播放数组下标
                this.playNextBoolean = true;
            }
        },

        //脸部表情更新函数
        updateFaceExpressions() {
            this.currentTime += 1 / this.framesPerSecond; // 更新时间
            this.currentFrame = Math.floor(this.currentTime * this.framesPerSecond); // 计算当前帧
            // 确保currentFrame在有效范围内
            this.currentFrame = Math.min(this.currentFrame, this.blendshapeArr[this.currentBufferIndex].length - 1);
            // 获取BlendShape权重数组
            const morphTargetInfluences = this.blendShapeMesh.morphTargetInfluences;
            // 获取当前帧的权重数据
            const currentWeights = this.blendshapeArr[this.currentBufferIndex][this.currentFrame];
            // 将权重数据设置到模型上
            if (currentWeights && morphTargetInfluences) {
                for (let i = 0; i < Math.min(morphTargetInfluences.length, currentWeights.length); i++) {
                    morphTargetInfluences[i] = currentWeights[i];
                }
            }
            this.updateTimer = setTimeout(this.updateFaceExpressions.bind(this), this.updateInterval);
        },

        //执行模型动作
        publicAction(url, type) {
            //执行动作
            const loader = new GLTFLoader();
            loader.load(url, (gltfData) => {

                // 停止前一个动画（如果存在）
                if (this.mixer) {
                    this.mixer.stopAllAction();
                    this.mixer.uncacheRoot(gltfData);//释放内存
                }

                //创建动画混合器
                this.mixer = new THREE.AnimationMixer(this.currentModel);
                // 初始化动画状态
                this.currentAnimation = this.mixer.clipAction(gltfData.animations[0]);

                // 设置动画的结束回调
                this.currentAnimation.setLoop(type == 0 ? THREE.LoopRepeat : THREE.LoopOnce);//是否循环播放动作

                this.currentAnimation.timeScale = 1; //默认1，可以调节播放速度
                // 设置 clampWhenFinished 为 true，动画播放完毕后会停留在最后一帧
                this.currentAnimation.clampWhenFinished = true;
                // 监听动画完成的事件
                this.currentAnimation.play();//播放动画
            });
        },

        //自适应屏幕
        onWindowResize() {
            // 更新相机纵横比  
            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();
            // 更新渲染器尺寸  
            this.renderer.setSize(window.innerWidth, window.innerHeight);
        },

        //各模型参数适配
        modelAdatation(modelName) {
            var y = ''
            switch (modelName) {
                case 'mouse':
                    y = -0.32;
                    break;
                case 'df':
                case 'bear':
                case 'kelin':
                case 'redcat':
                case 'cat':
                case 'newman':
                case 'qgirl':
                case 'girl03':
                case 'judy':
                    y = -0.9;
                    break;
                case 'vv':
                case 'zhe':
                    y = -0.91;
                    break;
                case 'girl04':
                    y = -0.9;
                    break;
                default:
                    break;
            }
            return y;
        },

        //给app传递消息
        postMsg(info) {
            this.$webUni.postMessage({
                data: {
                    action: "appSaveMsgInfo",
                    params: info,
                }
            })
        },

        //清空数据
        clearUpdata() {
            //清除模型动画
            cancelAnimationFrame(this.animationId);
            this.animationId = null;

            this.actionClick(false)

            // 清理资源，移除事件监听器  
            window.removeEventListener('resize', this.onWindowResize, false);
            if (this.renderer) {
                this.renderer.dispose();
            }
        }
    }
}
</script>
<style scoped>
.threeScene {
    width: 100%;
    height: 100%;
    max-height: 100%;
}
</style>