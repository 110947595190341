<template>
    <div class="page">
        <video ref="videoRtc" class="video" autoplay playsinline :poster="`/app_h5/image/Camera.png`"></video>
    </div>
</template>
<script>
export default {
    data() {
        return {
            module: '',
            webSocket: null,
            rtcWebSocket: null,
            peerConnection: null,
            rtc_video: null
        }
    },

    mounted() {
        this.rtc_video = this.$refs.videoRtc;
    },

    methods: {

        rtcStop(data) {
            this.webSocket = new WebSocket(this.$base.rct_ws + '9952/');
            this.postMsg({ msg: '', type: 'loading' });
            this.module = data;

            this.webSocket.onopen = () => {
                this.webSocket.send("ernerf:web" + this.module.uuid + ":rtc_stop")
            };

            this.webSocket.onmessage = (event) => {

                if (event.data === "ernerf:web" + this.module.uuid + ":rtc_stop") {
                    this.webSocket.send("ernerf:web" + this.module.uuid + ":rtc_start")
                    this.$emit('setRtcUpdata')
                }
            }

            this.rtcWebSocket = new WebSocket(this.$base.rct_ws + '5350/');
            this.rtcWebSocket.onopen = () => {
                this.rtcWebSocket.send("web" + this.module.uuid)
            };
            this.$emit('show')
            this.postMsg({ msg: '', type: 'ending' });
        },

        //数据更新
        Update() {
            this.webSocket.onmessage = (event) => {

                // 在这里处理接收到的消息
                if (event.data.substring(11) === "start:web" + this.module.uuid) {
                    console.log('=as===', event)
                    //连接webSocket
                    this.createSocket();
                }
                if (event.data === "full:" + this.module.uuid) {
                    //连接webSocket
                    this.postMsg({ msg: '房间人数已满', type: 'back' });
                }
            }



            //建立信令RTC
            this.initRTCPeer();
        },

        //创建一个websocket
        createSocket() {
            this.rtcWebSocket.onmessage = async (event) => {
                const message = JSON.parse(event.data);
                if (message.sdp) {
                    await this.peerConnection.setRemoteDescription(new RTCSessionDescription({
                        sdp: message.sdp,
                        type: message.type
                    }));
                    if (message.type === 'offer') {
                        const answer = await this.peerConnection.createAnswer();
                        await this.peerConnection.setLocalDescription(answer);
                        this.rtcWebSocket.send(JSON.stringify({ type: this.peerConnection.localDescription.type, sdp: this.peerConnection.localDescription.sdp }));
                    }
                } else if (message.candidate) {
                    await this.peerConnection.addIceCandidate(new RTCIceCandidate(message.candidate));
                }
            };
            this.rtcWebSocket.onclose = () => {
                setTimeout(() => {
                    this.createSocket()
                }, 1000);
            };
        },

        initRTCPeer() {
            // 添加STUN和TURN服务器配置
            const configuration = {
                iceServers: [
                    { urls: 'stun:47.116.196.10:3479' },
                    { urls: "turn:47.116.196.10:3479", username: "kamikasi", credential: "kmks" }
                ]
            };
            this.peerConnection = new RTCPeerConnection(configuration);
            this.peerConnection.oniceconnectionstatechange = () => {
                if (this.peerConnection.iceConnectionState == "connected") {
                    //推流中
                    this.peerConnection.getStats(null).then(stats => {
                        stats.forEach(report => {
                            console.log(report);
                        });
                    }).catch(error => {
                        console.error(`Error during getStats(): ${error}`);
                    });
                }
            };

            this.peerConnection.ontrack = (event) => {
                if (event.streams && event.streams[0].getVideoTracks().length > 0) {
                    const track = event.streams[0].getVideoTracks()[0];
                    if (track) {
                        // 如果当前有视频元素与 RTCPeerConnection 相关联，应停止并重新赋值
                        if (this.rtc_video.currentSrc) {
                            const existingStream = this.rtc_video.currentSrcObject;
                            if (existingStream) {
                                existingStream.getTracks().forEach(track => track.stop());
                            }
                        }
                        this.rtc_video.srcObject = event.streams[0];
                    }
                }
            };

            this.peerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                    if (this.rtcWebSocket && this.rtcWebSocket.readyState === WebSocket.OPEN) {
                        this.rtcWebSocket.send(JSON.stringify({ type: 'candidate', candidate: event.candidate }));
                    }
                } else {
                    console.log("No more ICE candidates.");
                }
            };
        },

        //给app传递消息
        postMsg(info) {
            this.$webUni.postMessage({
                data: {
                    action: "appSaveMsgInfo",
                    params: info,
                }
            })
        },

        //清空数据
        clearUpdata() {
            if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
                this.webSocket.send("ernerf:web" + this.module.uuid + ":rtc_stop")
            }
        }
    }
}
</script>
<style scoped>
.page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.video {
    width: 100%;
    max-height: 100%;
}
</style>